const steps = [
  {
    element: "#add-role-button",
    popover: {
      title: "Add Role",
      description: "Allows you to create a role for your organisation users",
      position: "bottom",
    },
  },
  {
    element: "#search-role",
    popover: {
      title: "Search for role",
      description:
        "Allows you to search for a specific role(s) for your organisation users",
      position: "bottom",
    },
  },
  {
    element: "#edit-role-button",
    popover: {
      title: "Edit Role",
      description: "Allows you to edit a role for your organisation users",
      position: "bottom",
    },
  },
  {
    element: "#remove-role-button",
    popover: {
      title: "Remove Role",
      description: "Allows you to remove a role for your organisation users",
      position: "bottom",
    },
  },
  {
    element: "#launch-role-button",
    popover: {
      title: "Launch Role",
      description: "Allows you to launch a role for your organisation users",
      position: "bottom",
    },
  },
];

export default steps;
