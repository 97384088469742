const steps = [
  {
    element: "#role-name",
    popover: {
      title: "Role-Name",
      description:
        "This is where you will need to add an appropiate name for the role",
      position: "right",
    },
  },
  {
    element: "#role-description",
    popover: {
      title: "Role-Description",
      description:
        "This is where you will need to add an appropiate description for the role",
      position: "right",
    },
  },
  {
    element: "#role-submit",
    popover: {
      title: "Role-Submission",
      description:
        "Once the above fields are correctly inputted, then you'll be able to submit the info of this role",
      position: "right",
    },
  },
];

export default steps;
